import { FC, useState } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { LinkModalProps } from "./types";

const UrlModal: FC<LinkModalProps> = (props) => {
  const [url, setUrl] = useState("");

  const linkToUrl = () => {
    props.executeLinkAction(url);
    props.close();
  };

  return (
    <Modal open={true} size="tiny" onClose={() => props.close()}>
      <Modal.Header>Podaj URL</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <Input
              fluid
              placeholder="Wpisz URL"
              type="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.close()}>Anuluj</Button>
        <Button disabled={!url} onClick={() => linkToUrl()} primary>
          Dodaj
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UrlModal;
