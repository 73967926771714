import { storyApi } from "../story-api";

export interface IChatMessage {
  readonly text: string;
  readonly author: string;
  readonly date: Date;
  seen?: boolean;
}
export type TChatMessages = ReadonlyArray<IChatMessage>;

export type TListener = (message: TChatMessages) => void;

class Room {
  protected messages: IChatMessage[];
  protected listeners: Set<TListener>;

  constructor(listener: TListener) {
    this.messages = [];
    this.listeners = new Set([listener]);
  }

  join(listener: TListener): void {
    this.listeners.add(listener);
    listener(this.messages);
  }

  leave(listener: TListener): boolean {
    this.listeners.delete(listener);
    return this.listeners.size < 1;
  }

  send(message: IChatMessage): void {
    this.messages = [...this.messages, message];
    this.listeners.forEach(listener => listener(this.messages));
  }
}

export class ChatErrorMessage extends Error implements IChatMessage {
  author = "(error)";
  text: string;
  date: Date;

  constructor(message: string) {
    super("Chat service error: " + message);
    this.text = message;
    this.date = new Date();
  }
}

export class ChatService {
  protected readonly url: URL;
  protected rooms: Map<string, Room> = new Map();
  protected hadError = false;

  protected _ws?: Promise<WebSocket>;
  protected get ws(): Promise<WebSocket> {
    return this._ws ? this._ws!.then(
      ws => ws.readyState === WebSocket.CLOSED ? this.connect() : ws
    ) : this.connect();
  }

  protected constructor() {
    const url = new URL(storyApi.url + "/chat/");
    url.protocol = url.protocol === "https:" ? "wss:" : "ws:";
    if (url.hostname === "localhost" && url.port === "8000") {
      url.port = "4320";
    }
    this.url = url;
  }

  protected static instance: ChatService;
  static getInstance() {
    if (!this.instance) this.instance = new ChatService();
    return this.instance;
  }

  async send(room: string, message: string): Promise<void> {
    const ws = await this.ws;
    ws.send(JSON.stringify({ room, message }));
  }

  join(room: string, listener: TListener) {
    const roomObject = this.rooms.get(room);
    if (roomObject) {
      roomObject.join(listener);
    } else {
      this.rooms.set(room, new Room(listener));

      this.ws.then(ws => {
        if (ws.readyState === WebSocket.CLOSING) {
          const error = new ChatErrorMessage("Chat connection is closing");
          console.error(error);
          listener([error]);
        } else {
          ws.send(JSON.stringify({ room, join: true }));
        }
      }).catch(error => {
        console.error("Chat service join error:", error);
      });
    }
  }

  leave(room: string, listener: TListener) {
    if (this.rooms.get(room)?.leave(listener)) {
      this.rooms.delete(room);
      this._ws?.then(ws => ws.send(JSON.stringify({ room, join: false })), () => void 0);
    }
    if (this.rooms.size < 1) {
      this.disconnect();
    }
  }

  distributeError(text: string): void {
    const error = new ChatErrorMessage(text);
    console.error(error);

    for (const room of this.rooms.values()) {
      room.send(error);
    }
  }

  protected connect(): Promise<WebSocket> {
    console.log("Chat service: connecting to:", this.url);
    this._ws = new Promise<WebSocket>((resolve, reject) => {
      const ws = new WebSocket(this.url);
      ws.onopen = () => {
        if (this.hadError) {
          this.distributeError("Chat reconnected after error");
        }
        resolve(ws);
      }
      ws.onmessage = this.onMessage.bind(this);
      ws.onerror = () => {
        this.distributeError("WebSocket error in chat connection.");
        reject();
      };
      ws.onclose = () => {
        this.distributeError("Chat connection has closed");
        this.rooms.clear();
      };
    });
    return this._ws;
  }

  protected disconnect(): void {
    console.log("Chat service: disconnecting...");
    this._ws?.then(ws => void ws.close(), () => void 0).finally(() => {
      this.hadError = false;
    });
  }

  protected onMessage({ data }: MessageEvent) {
    console.log("Chat service: received message:", data);

    let parsed: { room: string; message: string, author: string, date: string };
    let message: IChatMessage;
    try {
      parsed = JSON.parse(data);
      if (!parsed.room || !parsed.message || !parsed.author || !parsed.date) {
        throw new Error("Invalid response");
      }
      message = {
        text: parsed.message,
        author: parsed.author,
        date: new Date(parsed.date),
      };
    } catch (e) {
      console.error("Error parsing chat socket repsonse:", e);
      return;
    }

    const room = this.rooms.get(parsed.room);
    if (room) {
      room.send(message);
    } else {
      console.warn("No listeners for room:", parsed.room);
    }
  }
}
