import { ReactElement, RefObject } from "react";
import * as ReactDOM from "react-dom";

export function shadowMount(
  component: ReactElement[],
  node: HTMLElement,
  containerClass: string
) {
  let shadow = node.shadowRoot;
  if (!shadow) {
    shadow = node.attachShadow({ mode: "open" });
    // const styleSheet = document.head.querySelector("link[rel=stylesheet]").cloneNode();
    const styleSheet = document.createElement("link");
    styleSheet.rel = "stylesheet";
    styleSheet.href = "/fomantic-ui-css/semantic.min.css";
    const container = document.createElement("div");
    container.className = containerClass || "";
    container.style.margin = "0";
    shadow.appendChild(styleSheet);
    shadow.appendChild(container);
  }
  ReactDOM.render(component, shadow.lastChild as ReactDOM.Container);
}

export function shadowUnmount(node: Element) {
  const shadow = node.shadowRoot;
  if (shadow) ReactDOM.unmountComponentAtNode(shadow.lastChild as Element);
}

export function createStatusBar(
  parent: Element,
  statusRef: RefObject<HTMLElement | null>
) {
  if (!statusRef.current) return;

  const statusBar = parent.querySelector(".tox-statusbar__text-container");
  if (!statusBar) return;

  const div = document.createElement("div");
  div.appendChild(statusRef.current);
  div.style.fontWeight = "bold";
  const span = document.createElement("span");
  span.innerHTML = "\xa0|\xa0";
  span.style.fontWeight = "normal";
  div.appendChild(span);
  statusBar.insertBefore(div, statusBar.firstChild);
}
