import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { NotificationBag } from './bag';
import { SemanticCOLORS } from 'semantic-ui-react';

export const ToastNotification: FC<{
  text: ReactNode,
  color?: SemanticCOLORS,
  dismiss?: () => void,
}> = ({ text, color, dismiss }) => (
  <div className="toast-box">
    <div className={"ui compact toast " + (color || "")} onClick={dismiss}>
      <div className="content">
        { text }
      </div>
      <button type="button" title="Close" onClick={dismiss} className="close icon">
        <i className="close icon"></i>
      </button>
    </div>
  </div>
);

export const ToastBag: FC<Record<string, never>> = () => {
  return (
    <NotificationBag>{
      (children) => <ToastContainer>{ children }</ToastContainer>
    }</NotificationBag>
  );
};

const ToastContainer = styled.div.attrs({ className: "ui toast-container top right" })`
  .toast-box .ui.toast {
    padding-right: 1.7em;

    button.close.icon {
      border: none;
      background: none;
      appearance: none;
      color: inherit;
      padding: 0;

      > * {
        margin: 0;
      }
    }
  }
`;
