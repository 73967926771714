const TITLE_LENGTH_LIMIT = 30;


export function shortenTitle(title?: string): string {
  title = title ? title.trim() : "";
  const words = title.split(/\s+/g);
  title = words.shift() || "";
  while (words.length > 0 && (title.length + words[0].length + 1) < TITLE_LENGTH_LIMIT) {
    title += " " + words.shift();
  }
  if (words.length > 0) {
    title += "\u2026"; // ellipsis
  }
  return title;
}

export function checkCommandKey(event: KeyboardEvent | MouseEvent | TouchEvent) {
  const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  return isMacLike ? event.metaKey : event.ctrlKey;
}
