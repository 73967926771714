import { FC, WeakValidationMap } from "react";
import { ILexia } from "../../story-api";
import PropTypes from "prop-types";

export interface AllSidebarProps {
  lexia: ILexia;
  onNavigate: () => void;
}

export type SidebarProps = Partial<AllSidebarProps>;

export type TSidebar<T = NonNullable<any>> = FC<T> & {
  propTypes?: WeakValidationMap<T>;
}

export function Sidebar<T extends TSidebar<Record<string, never>>>(fc: T): T;
export function Sidebar<T extends TSidebar<NonNullable<unknown>>>(fc: T, ref?: Pick<T, "propTypes">): T;
export function Sidebar<T extends TSidebar<any>>(fc: T, ref?: Pick<T, "propTypes">): T {
  fc.propTypes = ref ? ref.propTypes : {};
  return fc;
}

type TLexiaSidebar = TSidebar<Pick<AllSidebarProps, "lexia" | "onNavigate">>;
const TLexiaSidebar = {
  propTypes: {
    lexia: PropTypes.object.isRequired as PropTypes.Validator<NonNullable<ILexia>>,
    onNavigate: PropTypes.func.isRequired
  }
};
export { TLexiaSidebar };

export function LexiaSidebar<T extends TLexiaSidebar>(fc: T): T {
  return Sidebar(fc, TLexiaSidebar);
}
