/* eslint-disable */
import raw_i_help from "./help.menu";


function menu(Command, Link, Submenu, Divider, Import) {
	const i_help = raw_i_help(...arguments);
	return [
		Submenu("Plik", [
			Command("Nowa leksja", "New", "file outline"),
			Divider(),
			Command("Usuń leksję", "Delete", "trash"),
			Command("Podgląd", "Preview", "eye"),
			Command("Drukuj...", "mcePrint", "print"),
			Divider(),
			Command("Wyjdź", "Quit", "arrow left")
		]),
		Submenu("Edytuj", [
			Command("Cofnij", "Undo", "undo"),
			Command("Powtórz", "Redo", "redo"),
			Divider(),
			Command("Wytnij", "Cut", "cut"),
			Command("Kopiuj", "Copy", "copy"),
			Command("Wklej", "Paste", "paste"),
			Divider(),
			Command("Zaznacz wszystko", "SelectAll", "content"),
			Divider(),
			Command("Znajdź i zamień...", "Search", "search")
		]),
		Submenu("Wstaw", [
			Command("Obrazek...", "mceImage", "image"),
			Command("Multimedia...", "mceMedia", "video play"),
			Divider(),
			Command("Znak specjalny...", "mceShowCharmap", "paragraph"),
			Command("Linia pozioma", "InsertHorizontalRule", "arrows alternate horizontal"),
			Divider(),
			Command("Kotwica...", "mceAnchor", "anchor"),
			Divider(),
			Submenu("Data i czas...", Import("DateTimeMenu"))
		]),
		Submenu("Format", [
			Command("Pogrubienie", "Bold", "bold"),
			Command("Kursywa", "Italic", "italic"),
			Command("Podkreślenie", "Underline", "underline"),
			Command("Przekreślenie", "Strikethrough", "strikethrough"),
			Command("Indeks górny", "Superscript", "superscript"),
			Command("Indeks dolny", "Subscript", "subscript"),
			Command("Kod", "CodeSample", "code"),
			Divider(),
			Submenu("Bloki", Import("BlockMenu")),
			Submenu("Kroje pisma", Import("FontMenu")),
			Submenu("Rozmiar czcionki", Import("FontSizeMenu")),
			Submenu("Wysokość wiersza", Import("LineHeightMenu")),
			Submenu("Kolor tekstu", Import("TextColorMenu")),
			Divider(),
			Command("Tło...", "Background", "object group")
		]),
		Submenu("Widok", [
			Command("Pełne formatowanie", ["View","WYSIWYG"], "pen fancy"),
			Command("Uproszczony", ["View","Simple"], "paragraph"),
			Command("Kod HTML", ["View","HTML"], "code"),
			Divider(),
			Command("Podgląd", "Preview", "eye"),
			Command("Mapa", "Map", "sitemap")
		]),
		i_help
	];
};

export default menu;
