import { FC, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { useLexiaList, useStoryLineList } from "../../../queries";
import { ILexia, IStoryLine } from "../../../story-api";
import SearchableList from "./SearchableList";
import { ExternalLexiaModalProps } from "./types";
import PleaseWaitComponent from "../../misc/pleaseWaitComponent";

const ExternalLexiaModal: FC<ExternalLexiaModalProps> = ({
  executeLinkAction,
  close,
  lexia
}) => {
  const { data, isLoading, isError } = useStoryLineList(lexia.story);
  const storyLines = data?.storyLines;

  const [selectedStoryLine, setSelectedStoryLine] = useState<IStoryLine | null>(lexia.collection);

  const { data: lexiaList } = useLexiaList(selectedStoryLine, {
    enabled: !!selectedStoryLine,
  });

  const [selectedLexia, setSelectedLexia] = useState<ILexia | null>(lexia);

  const addLink = () => {
    if (!selectedLexia) return;
    executeLinkAction(selectedLexia);
    close();
  };

  return (
    <Modal open={true} size="small" onClose={() => close()}>
      <Modal.Header>Połącz się z cudzą fabułą</Modal.Header>
      <Modal.Content>{
        isError ? <p className="ui negative message">Błąd pobierania danych</p> :
        isLoading ? <PleaseWaitComponent /> :
        <TwoColumnLayout>
          <SearchableList<IStoryLine>
            title="Wybierz linię fabularną:"
            searchPlaceholder="Wpisz nazwę linii fabularnej"
            onItemClick={setSelectedStoryLine}
            activeItem={selectedStoryLine || null}
            list={storyLines || []}
          />
          <SearchableList<ILexia>
            title="Wybierz leksję:"
            searchPlaceholder="Wpisz tytuł"
            onItemClick={setSelectedLexia}
            activeItem={selectedLexia || null}
            list={lexiaList || []}
          />
        </TwoColumnLayout>
      }</Modal.Content>
      <Modal.Actions>
        <Button onClick={() => close()}>{ isError ? "Zamknij" : "Anuluj" }</Button>
        {isError ? null : <Button onClick={() => addLink()} primary disabled={!selectedLexia}>
          Dodaj
        </Button>}
      </Modal.Actions>
    </Modal>
  );
};

const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
`;

export default ExternalLexiaModal;
