import { FC, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import styled from "styled-components";
import ConditionalLinkEditorComponent from "../../links/editor";
import { ConditionalLinkModalProps } from "./types";

const ConditionalModal: FC<ConditionalLinkModalProps> = (props) => {
  const [script, setScript] = useState<string | undefined>();
  const [urls, setUrls] = useState<string[] | undefined>();

  const linkToScript = () => {
    if (!script) return;
    if (!urls?.length) throw new Error("Missing link targets");
    props.executeLinkAction(script, urls as [string, ...string[]]);
    props.close();
  };

  const onChange = (script: string | undefined, targets: string[]) => {
    setScript(script);
    setUrls(script ? targets : undefined);
  };

  return (
    <Modal open={true} onClose={() => props.close()}>
      <Modal.Header>Link warunkowy</Modal.Header>
      <StyledContent>
        <ConditionalLinkEditorComponent change={onChange} storyLine={props.storyLine} />
      </StyledContent>
      <Modal.Actions>
        <Button onClick={() => props.close()}>Anuluj</Button>
        <Button disabled={!script} onClick={() => linkToScript()} primary>
          Dodaj
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const StyledContent = styled(Modal.Content)`
  height: 62vh;
  overflow: auto;
  display: flex;
  flex-flow: column;
`;

export default ConditionalModal;
