import { FC, useState } from "react";
import { Button, Form, Label, Modal, Select } from "semantic-ui-react";
import styled from "styled-components";
import { LinkModalProps } from "./types";
import config, { ContactLinkType } from "../../../services/config";

const HelpLabelContainer = styled.div`
  text-align: right;

  & > * {
    text-align: left;
    white-space: pre-line;
  }
`;

const ContactModal: FC<LinkModalProps> = (props) => {
  // TODO: show loading indicator
  const [contactType, setContactType] = useState<ContactLinkType | undefined>(undefined);
  const [contact, setContact] = useState<string>("");
  const [valid, setValid] = useState<boolean>(false);

  const linkToContact = () => {
    const uri = contactType!.proto + contact;
    props.executeLinkAction(uri);
    props.close();
  };

  const selectOptions: {
    key: string;
    value: string;
    text: string;
  }[] = config.contactProtocols.map(({proto, label}) => ({
    key: proto,
    value: proto,
    text: label
  }));

  let error: string | null = null;
  if (!contactType) {
    error = "Wybierz typu kontaktu";
  } else if (!contact || !valid) {
    const isNumber = contactType.type === "number" || /\d+/.test(contactType.placeholder);
    const isUsername = !isNumber && contactType.type !== "email";
    if (contact) {
      error = isUsername ? "Nazwa użytkownika jest niepoprawna" : (
        "Podany " + (isNumber ? "numer" : "adres") + " jest niepoprawny");
    } else {
      error = "Podaj " + (isUsername ? "nazwę użytkownika" : isNumber ? "numer" : "adres");
    }
  }

  // Revisit the requirements, why have multiple text inputs in a single choice radio group?
  return (
    <Modal open={true} size="tiny" onClose={() => props.close()}>
      <Modal.Header>Link kontaktowy</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <div className="ui fluid labeled input">
              <Select
                placeholder="(wybierz)"
                className="label"
                options={selectOptions}
                value={contactType?.proto}
                onChange={(_, {value}) =>
                  setContactType(config.contactProtocols.find(link => link.proto === value))
                }
              />
              <input
                disabled={contactType == undefined}
                type={contactType?.type}
                pattern={String(contactType?.pattern).slice(1, -1)}
                placeholder={contactType?.placeholder}
                value={contact}
                onChange={(e) => {
                  setContact(e.target.value);
                  setValid(e.target.validity.valid && !!e.target.value);
                }}
              />
            </div>
            { contactType?.help ?
              <HelpLabelContainer><Label pointing>{ contactType.help }</Label></HelpLabelContainer>
            : null }
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        { error ? <StyledError>{
          (contactType && contact) ? <span className="ui red text">{ error }</span> : error
        }</StyledError> : null }
        <Button onClick={() => props.close()}>Anuluj</Button>
        <Button primary
          disabled={!valid || !contactType || !contact}
          onClick={() => linkToContact()}>
          Dodaj
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const StyledError = styled.div`
  float: left;
  padding: .78571429em 1.5em;
`;

export default ContactModal;
