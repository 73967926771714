import { ChangeEvent, useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import { IComparableEntity } from "../../../story-api";

type SearchableListProps<ItemType> = {
  title: string;
  searchPlaceholder: string;
  activeItem: ItemType | null;
  list: ReadonlyArray<ItemType>;
  onItemClick?: (item: ItemType) => void;
};

const SearchableList = <ItemType extends IComparableEntity>({
  list,
  title,
  searchPlaceholder,
  activeItem,
  onItemClick,
}: SearchableListProps<ItemType>) => {
  const [searchPhrase, setSearchPhrase] = useState("");

  const [filteredList, setFilteredList] = useState(list);

  useEffect(() => {
    const lowerCasePhrase = searchPhrase.trim().toLocaleLowerCase();
    setFilteredList(
      list.filter((item) => item.toString().toLocaleLowerCase().includes(lowerCasePhrase))
    );
  }, [searchPhrase, list]);

  return (
    <div>
      <Title>{title}</Title>
      <SearchInputContainer>
        <SearchInput
          value={searchPhrase}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchPhrase(e.target.value)}
          placeholder={searchPlaceholder}
        />
        <Icon name="search" color={"grey"} />
      </SearchInputContainer>
      <List>
        {filteredList.map((item) => {
          return (
            <ListItem
              active={!!activeItem && activeItem.equals(item)}
              onClick={() => onItemClick && onItemClick(item)}
              key={item.hashCode()}
            >
              {item.toString()}
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

const borderColor = "#dededf";

const Title = styled.div`
  padding-bottom: 5px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none; // TODO: accessibility requirements?
  width: 100%;
  padding-right: 10px;
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid ${borderColor};
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  width: 100%;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  height: 250px;
  overflow-y: auto;
  border: 1px solid ${borderColor};
  border-radius: 0 0 4px 4px;
`;

type ListItemProps = {
  active?: boolean;
};
const ListItem = styled.li<ListItemProps>`
  padding: 6px 10px;
  cursor: pointer;

  background: ${(p) => (p.active ? "rgba(0,0,0,0.04)" : "transparent")};

  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

export default SearchableList;
