import { Editor, IAllProps } from "@tinymce/tinymce-react";
import { FC } from "react";
import { WebTarget, styled } from "styled-components";
import config from "../../services/config";


type IUnstyledEditorProps = Omit<IAllProps, "tinymceScriptSrc"> & {
  baseUrl?: string;
};
export const UnstyledEditor: FC<IUnstyledEditorProps> = ({ baseUrl, init, ...props }: IUnstyledEditorProps) => 
  <Editor
    tinymceScriptSrc={"/tinymce/tinymce.js"}
    init={Object.assign({}, config.tinyMceConfig, { document_base_url: baseUrl }, init || {})}
    {...props} />;


type IStyledEditorProps = IUnstyledEditorProps & {
  as?: WebTarget;
};
export const StyledEditor: FC<IStyledEditorProps> = ({ as, init, ...props }: IStyledEditorProps) => {
  if (!init) init = {};

  return <EditorContainer as={as}>
    <UnstyledEditor init={{
      height: "max(200px, 38vh)",
      plugins: [],
      statusbar: false,
      highlight_on_focus: true,
      ...init
    }} {...props} />
  </EditorContainer>;
};

const EditorContainer = styled.div`
  .tox-tinymce {
    border: 1px solid rgba(34, 36, 38, 0.15); /* @textAreaBorder */
    border-radius: 4px; /* @inputBorderRadius */
    transition: border-color 0.1s ease; /* @textAreaTransition */
  }

  .tox-edit-focus {
    border-color: #85b7d9; /* @textAreaFocusBorderColor */
  }

  .tox-edit-area:before {
    display: none;
  }
`;
