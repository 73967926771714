import { Link } from "gatsby";
import { FC } from "react";
import { Button, Header, Icon, List, Segment } from 'semantic-ui-react';
import styled from "styled-components";
import { useNeighborsMap } from "../../queries";
import { IBaseTitledEntity, IMapLink } from "../../story-api";
import { SidebarBase } from '../menu/SidebarBase';
import { LexiaSidebar } from "../menu/SidebarTypes";

export const NeighborsMapSidebar = LexiaSidebar(({ lexia, onNavigate }) => {
  const { data, isLoading, error } = useNeighborsMap(lexia);
  if (error) console.error(error);

  const lists: [string, ReadonlyArray<IMapLink>][] = data ? [
    ["Linki wewnętrzne", data.internal],
    ["Linki wychodzące", data.outgoing],
    ["Linki przychodzące", data.incoming],
    ["Połączenia przyległych leksjami", data.external]
  ] : [];
  lists.sort((a, b) => b[1].length - a[1].length);

  const LexiaLink = ({ to }: { to: IBaseTitledEntity }) =>
    lexia.url.href === to.url.href ? <Icon name="file outline" className="blue" /> :
    <Link to={to.editPath} onClick={onNavigate}>{ to.title }</Link>;

  const renderedLists = lists.map(([header, links]) => {
    const noLinks = !(links.length > 0);

    return <LinkListContainer key={header}>
      <Header as="h5" attached="top" block disabled={ noLinks }>{header}</Header>
      { noLinks ? <Segment attached="bottom" disabled>
        <Icon name="unlink" /> brak
      </Segment> : <Segment attached="bottom" style={{ padding: 0 }}>
        <List divided>{
          links.map((link, idx) =>
            <StyledListItem key={idx}>
              <List.Content>
                <StyledListHeader inText={ link.inText }>
                  { link.inText ? <TextLink>{ link.label }</TextLink> : <DefaultLink>{ link.label }</DefaultLink> }
                </StyledListHeader>
                <List.Description>
                  <LexiaLink to={ link.source } /> &rarr; <LexiaLink to={ link.target } />
                </List.Description>
              </List.Content>
            </StyledListItem>
          )
        }</List>
      </Segment> }
    </LinkListContainer>
  });

  const errorStr = error ? ((error + "") || "Nieznany błąd") : undefined;
  return <SidebarBase header="Sąsiedztwo" buttons={
        <Button as={Link} to={lexia.mapPath} primary>Pokaż jako mapę</Button>
      } loading={isLoading} error={errorStr}>{ renderedLists }</SidebarBase>;
});

const TextLink: FC<{ children: string }> = ({ children }) =>
  <q title={ children }>{ children }</q>;

const LinkListContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 1em;
  }
`;

const StyledListItem = styled(List.Item)`
  padding: .78571429rem 1rem !important;
`;

const StyledListHeader = styled(List.Header)<{ inText: boolean, }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: .4rem !important;
`;

const DefaultLink = styled.span.attrs({ className: "ui text grey" })`
  font-style: italic;
`;
