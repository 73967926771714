import { useEffect, useState } from "react";
import { ContextMenuPosition } from "./types";

type ActiveMenu = {
  isOpen: boolean;
  position: ContextMenuPosition;
};

type ActiveSubMenu<Item extends string = string> = {
  name: Item | null;
  position: ContextMenuPosition;
};
export const useContextMenu = <Item extends string>(menu: ActiveMenu) => {
  const [activeSubMenu, setActiveSubMenu] = useState<ActiveSubMenu<Item>>({
    name: null,
    position: { top: null, left: null },
  });

  useEffect(() => {
    closeOpenSubMenu();
  }, [menu.isOpen, menu.position.left, menu.position.top]);

  const closeOpenSubMenu = () => {
    setActiveSubMenu({
      name: null,
      position: { top: null, left: null },
    });
  };

  const openSubMenu = ({
    e,
    item,
    isWide,
  }: {
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>;
    item: Item;
    isWide?: boolean;
  }) => {
    const { top, right, left } = e.currentTarget.getBoundingClientRect();

    if (willOverflowRightScreenEdge(right)) {
      const menuWidth = isWide ? WIDE_MENU_WIDTH : MENU_WIDTH;
      setActiveSubMenu({
        name: item,
        position: {
          top: top,
          left: left - menuWidth - CONTEXT_MENU_GAP,
        },
      });
    } else {
      setActiveSubMenu({
        name: item,
        position: { top: top, left: right + CONTEXT_MENU_GAP },
      });
    }
  };

  const willOverflowRightScreenEdge = (rightRect: DOMRect["right"]) =>
    rightRect + WIDE_MENU_WIDTH > window.innerWidth;

  const TOP_EDITOR_MENU_HEIGHT_PX = 100;
  const TOP_BORDER_OFFSET_PX = 1;
  const CONTEXT_MENU_GAP = 5;
  // hardcoded values from semantic
  const MENU_WIDTH = 210;
  const WIDE_MENU_WIDTH = 300;

  return {
    activeSubMenu,
    openSubMenu,
    TOP_EDITOR_MENU_HEIGHT_PX,
    TOP_BORDER_OFFSET_PX,
    CONTEXT_MENU_GAP,
    MENU_WIDTH,
    WIDE_MENU_WIDTH,
  };
};
