import { FC } from "react";

const PleaseWaitComponent: FC = () => {
  return (
    <div style={{ padding: "1em" }}>
      <div className="ui active inverted dimmer">
        <div className="ui text loader"></div>
      </div>
    </div>
  );
};

export default PleaseWaitComponent;
