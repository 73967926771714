import { ReactElement, ReactNode } from 'react';

export const DEFAULT_DURATION = 10E3;
export const MAX_NOTIFICATIONS = 10;

export interface INotifierContext {
  notifications: ReadonlyArray<INotification>;
  notify: (children: ReactElement) => void;
  dismissToast: (id: number) => void;
  dismissAllToasts: () => void;
  alert: (message: ReactNode, isError: boolean) => void;
}

export interface INotification {
  id: number;
  children: ReactElement;
}
