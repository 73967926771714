import { format } from "date-fns";
import { useMemo } from 'react';
import { Dropdown } from "semantic-ui-react";
import styled from "styled-components";
import { Editor as TinyMCEEditor } from "tinymce";
import rawMenu from "../../../../../editor/src/menus/editor.menu";
import { CmdMenuItem, ICmdMenuItem, TMainMenu, constructMenu } from "../../menu/MenuTypes";
import ColorPickerSubMenu from "./ColorPickerSubMenu";

const fontList = [
  "Andale Mono",
  "Arial",
  "Arial Black",
  "Book Antiqua",
  "Comic Sans MS",
  "Courier New",
  "Georgia",
  "Helvetica",
  "Impact",
  "Symbol",
  "Tahoma",
  "Terminal",
  "Times New Roman",
  "Trebuchet MS",
  "Verdana",
];

const ColorPickerDropdownMenu = styled(Dropdown.Menu)`
  & {
    min-width: 0 !important;
  }

  & > .item {
    padding: 0 !important;
  }
`;

export const NormalizedBlock = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  p {
    margin: 0;
  }
`;

const dateTimeMenu = ["HH:mm:ss", "yyyy-MM-dd", "hh:mm:ss a", "MM/dd/yyyy"].map(
  (formatString) => {
    const fmt = format(new Date(), formatString);
    return CmdMenuItem(fmt, ["mceInsertTime", fmt]);
  }
);
const blockMenu = [
  BlockMenuItem("Akapit", "p"),
  BlockMenuItem("Nagłówek 1", "h1"),
  BlockMenuItem("Nagłówek 2", "h2"),
  BlockMenuItem("Nagłówek 3", "h3"),
  BlockMenuItem("Nagłówek 4", "h4"),
  BlockMenuItem("Nagłówek 5", "h5"),
  BlockMenuItem("Nagłówek 6", "h6"),
  BlockMenuItem("Preformatowany", "pre")
];
const fontMenu = fontList.map(FontMenuItem);
const fontSizeMenu = [8, 10, 12, 14, 18, 24, 36].map(
  (size) => CmdMenuItem(`${size}pt`, ["FontSize", `${size}pt`])
);
const lineHeightMenu = [1, 1.1, 1.2, 1.3, 1.4, 1.5, 2].map(
  (h) => CmdMenuItem(`${h}`, ["LineHeight", h])
);
const textColorMenu = (editor: TinyMCEEditor | undefined) => {
  const TextColorMenu = () => (
    <ColorPickerDropdownMenu>
      <ColorPickerSubMenu editor={editor} /> { /* TODO: changes to editor */ }
    </ColorPickerDropdownMenu>
  );
  return TextColorMenu;
};

export function getEditorMenu(editor: TinyMCEEditor | undefined): TMainMenu {
  return useMemo(() => constructMenu(rawMenu, (name: string) => {
    switch(name) {
      case "DateTimeMenu":
        return dateTimeMenu;
      case "BlockMenu":
        return blockMenu;
      case "FontMenu":
        return fontMenu;
      case "FontSizeMenu":
        return fontSizeMenu;
      case "LineHeightMenu":
        return lineHeightMenu;
      case "TextColorMenu":
        return textColorMenu(editor);
    }
  }), [editor]);
}

function BlockMenuItem(
  text: string,
  Tag: keyof JSX.IntrinsicElements,
  other?: Partial<ICmdMenuItem>
): ICmdMenuItem {
  return {
    text: <Tag>{ text }</Tag>,
    cmd: ["FormatBlock", Tag],
    as: NormalizedBlock,
    ...other
  };
}

function FontMenuItem(fontFamily: string): ICmdMenuItem {
  return {
    text: <span style={{ fontFamily }}>{ fontFamily }</span>,
    cmd: ["FontName", fontFamily]
  };
}
