import { FC, InputHTMLAttributes, useEffect, useId, useState } from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

type FileInputProp = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'onChange'> & {
  onChange: (files: FileList | null) => void;
};
export const FileInput: FC<FileInputProp> = ({
  id, onChange, tabIndex, value: origValue, ...props
}) => {
  const [value, setValue] = useState<string>("");
  const generatedId = useId();
  id = id || generatedId;

  useEffect(() => {
    if (origValue && typeof origValue === "string") {
      setValue(origValue);
    }
  }, [origValue]);

  const onChangeEx = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = e.target.files || null;
    const length = files?.length || 0;
    if (length === 1) {
      setValue(files![0].name);
    } else if (length > 1) {
      setValue(`${length} plików`);
    }
    onChange(files);
  }

  const clear = () => {
    setValue("");
    onChange(null);
  }

  return <StyledContainer className="ui right labeled icon input">
    <Icon name="remove" link onClick={clear} tabIndex={0} />
    <input type="text" value={value} readOnly />
    <label className="ui label button" htmlFor={id} tabIndex={ tabIndex || 0 }>
      Wybierz plik&hellip;
    </label>
    <input type="file" id={id} onChange={onChangeEx} {...props} />
  </StyledContainer>;
};

const StyledContainer = styled.div`
  i.icon {
    right: 8.8em !important;
  }

  .ui.button {
    width: 8.8em;
  }

  input[type="file"] {
    display: none;
  }
`;
