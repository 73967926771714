import { Link, navigate } from "gatsby";
import { useState } from "react";
import { DropdownItemProps, Form, FormField, List, Select } from "semantic-ui-react";
import styled from "styled-components";
import { invalidateLexiaList, useLexiaList, useStoryLineList } from "../../queries";
import { ILexia, IStoryLine } from "../../story-api";
import { SidebarBase } from "../menu/SidebarBase";
import { LexiaSidebar } from "../menu/SidebarTypes";
import { Confirm } from "../misc/confirmation";
import { useNotificationApi } from "../notifications/api";

const LexiaListSidebar = LexiaSidebar((props) => {
  const { lexia, onNavigate } = props;
  const [toDelete, setToDelete] = useState<ILexia | null>(null);
  const [selectedStoryLine, setSelectedStoryLine] = useState<IStoryLine>(lexia.collection);

  const lexiaListLoader = useLexiaList(selectedStoryLine);
  const storyLineListLoader = useStoryLineList(lexia.story);
  const lexiaList: ReadonlyArray<ILexia> | undefined = lexiaListLoader.data;
  const storyLineList: ReadonlyArray<IStoryLine> | undefined = storyLineListLoader.data?.storyLines;
  const isError = lexiaListLoader.isError || storyLineListLoader.isError;
  const isLoading = lexiaListLoader.isLoading || storyLineListLoader.isLoading;
  const { notify } = useNotificationApi();

  const storyLineOptions: DropdownItemProps[] | null =
    (!storyLineList || storyLineList.length < 2) ? null :
    storyLineList.map((storyLine) => {
      const key = storyLine.url.href;
      return {key, value: key, text: storyLine.toString()};
    });

  const changeStoryLine = (e: unknown, {value}: {value?: unknown}) => {
    if (typeof value !== "string") return console.error("This should never happen");
    const storyLine = storyLineList!.find((storyLine) => storyLine.url.href === value);
    if (!storyLine) return console.error("This should never happen");
    setSelectedStoryLine(storyLine);
  };

  const deletionConfirmed = () => {
    if (!toDelete) return console.error("This should never happen");
    const lexia = toDelete;
    setToDelete(null);

    lexia.delete().then(() => {
      if (lexia.equals(props.lexia)) navigate(lexia.collection.editPath);
    }, () => {
      notify("Nie udało się usunąć leksji", "red");
    }).finally(() => {
      invalidateLexiaList(lexia.collection);
    });
  };

  return <SidebarBase header="Lista leksji" loading={isLoading} error={isError}>
    { (isLoading || isError) ? null : <>
      { storyLineOptions && <Form>
        <FormField>
          <label>Linia fabularna:</label>
          <Select
            options={storyLineOptions}
            value={selectedStoryLine.url.href}
            onChange={changeStoryLine} />
        </FormField>
        <FormField>
          <label>Leksja:</label>
        </FormField>
      </Form> }
      <List animated selection style={{marginTop: 0}}>
        {(lexiaList || []).map((item: ILexia) => {
          const isActive = lexia.equals(item);
          return <List.Item key={item.url.href} active={isActive} as={Link} to={item.editPath}>
            { item.url.pathname.endsWith("/index.html") ? null :
              <StyledAction name="close" className="right floated" onClick={() => setToDelete(item)} /> }
            <List.Icon name={isActive ? "file alternate outline" : "file outline"} />
            <List.Content>
              <List.Header onClick={() => onNavigate()}>
                { item.title }
              </List.Header>
            </List.Content>
          </List.Item>;
        })}
      </List>
      <Confirm
        negative
        header="Potwierdź usunięcie"
        confirmButton="Usuń"
        cancelButton="Anuluj"
        open={toDelete != null}
        onCancel={() => setToDelete(null)}
        onConfirm={deletionConfirmed}>
          Czy na pewno chcesz <b>bezpowrotnie</b> usunąć leksję<br /><q>{toDelete?.title}</q>?
      </Confirm>
    </> }
  </SidebarBase>;
});

const StyledAction = styled(List.Icon).attrs({ tabIndex: 0 })`
  color: #767676 !important;

  &:hover, &:focus {
    color: #1b1c1d !important;
  }
`;

export default LexiaListSidebar;
