import { FC } from "react";
import { Dropdown } from "semantic-ui-react";
import { GithubPicker } from "react-color";
import styled from "styled-components";

const ColorPickerSubMenu: FC<{ applyColor: (color: string) => void }> = ({ applyColor }) => {
  const colors = [
    "#BFEDD2",
    "#FBEEB8",
    "#F8CAC6",
    "#ECCAFA",
    "#C2E0F4",
    "#2DC26B",
    "#F1C40F",
    "#E03E2D",
    "#B96AD9",
    "#3598DB",
    "#169179",
    "#E67E23",
    "#BA372A",
    "#843FA1",
    "#236FA1",
    "#ECF0F1",
    "#CED4D9",
    "#95A5A6",
    "#7E8C8D",
    "#34495E",
    "#000000",
    "#FFFFFF",
    "REMOVE",
  ];

  return (
    <Dropdown.Item as={PickerWrapper}>
      <GithubPicker
        triangle="hide"
        width="135px"
        colors={colors}
        onChangeComplete={({ hex }) => applyColor(hex)}
      />
    </Dropdown.Item>
  );
};

const PickerWrapper = styled.div`
  .github-picker {
    box-shadow: none !important;
    border: none !important;

    [title="REMOVE"] {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        width: 135%;
        height: 2px;
        background: red;
        transform: rotate(45deg);
        transform-origin: top left;
      }
    }
  }
`;

export default ColorPickerSubMenu;
