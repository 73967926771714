import { navigate } from "gatsby";
import EditorComponent from "../components/editor/editor";
import LinkEditorComponent from "../components/links/editor";

// TODO: figure out what type props are
const EditPage = (props: any) => {
  if (typeof document === "undefined") return;
  const path = props["*"] + "";
  if (!path) {
    return <LinkEditorComponent />;
    // return navigate("/o/");
  } else if (/^[^/]+\/?$/.test(path)) {
    // TODO: update when story lines are implemented
    return navigate("/e/" + path.replace(/\/$/, "") + "/index.html");
  }
  return <EditorComponent path={path} />;
};

export default EditPage;
