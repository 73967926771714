import { upperFirst } from "lodash-es";
import { FC, useState } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { Editor as TinyMCEEditor } from 'tinymce';
import { LexiaHtml } from "../../../types";
import { StyledEditor } from "../styledEditor";

const MAX_NAME_LENGTH = 50;

type NewLexiaModalProps = {
  createNewLexia: (
    newLexiaName: string,
    lexiaContent: LexiaHtml
  ) => void;
  close: () => void;
  editor?: TinyMCEEditor;
  baseUrl?: string;
};
const NewLexiaModal: FC<NewLexiaModalProps> = ({
  createNewLexia,
  close,
  editor: mainEditor,
  baseUrl,
}) => {
  const [name, setName] = useState(() => getInitialName(mainEditor));
  const [editor, setEditor] = useState<TinyMCEEditor>();

  const linkToNewLexia = () => {
    if (!editor) console.error("Editor not initialized!");
    close();
    createNewLexia(name, editor?.getContent() || "");
  };

  return (
    <Modal open={true} size="small" onClose={() => close()}>
      <Modal.Header>Dodawanie nowej leksji</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <Input
              fluid
              placeholder="Wpisz tytuł leksji (wymagane)"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <StyledEditor baseUrl={baseUrl} onInit={(_event, e) => setEditor(e)} />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => close()}>Anuluj</Button>
        <Button disabled={!name} onClick={() => linkToNewLexia()} primary>
          Dodaj
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

function getInitialName(editor?: TinyMCEEditor): string {
  if (!editor) return "";
  const selection = editor.selection.getContent();
  if (!selection) return "";

  const div = document.createElement("div");
  div.innerHTML = selection;
  let name: string = div.textContent || "";
  name = name.replace(/\s+/g, " ").trim();

  if (name.length > MAX_NAME_LENGTH) {
    const words = name.split(" ");
    name = words[0];
    for (let i = 1; i < words.length; ++i) {
      if (name.length + words[i].length >= MAX_NAME_LENGTH) break;
      name += " " + words[i];
    }
  }

  name = name.replace(/^\W+/, '').replace(/\W+$/, '');
  return upperFirst(name);
}

export default NewLexiaModal;
