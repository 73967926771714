import { navigate } from "gatsby";
import { FC, useEffect, useRef, useState } from "react";
import { EditorEvent, Editor as TinyMCEEditor } from "tinymce";
import { useCreateLexia } from "../../mutations";
import { invalidateLexiaList, useLexia } from "../../queries";
import { useLastEdited } from "../../services/lastEdited";
import { checkCommandKey } from "../../services/utils";
import { ILexia } from "../../story-api";
import FragmentWrapper, { IBackground } from "../../story-api/fragment-wrapper";
import { getBaseUrl, getTargetLexiaEditPath, relativeUrl } from "../../story-api/path";
import { LexiaHtml } from "../../types";
import { MenuLayout } from "../menu/MenuLayout";
import { Format, TActiveFormats, TCommand } from "../menu/MenuTypes";
import { Confirm } from "../misc/confirmation";
import { useNotificationApi } from "../notifications/api";
import ContextMenu from "./ContextMenu/ContextMenu";
import { ContextMenuPosition } from "./ContextMenu/types";
import { useContextMenu } from "./ContextMenu/useContextMenu";
import { getEditorMenu } from "./EditorMenu/EditorMenu";
import EditorMenuToolbar from "./EditorMenu/EditorMenuToolbar";
import { BackgroundModal, IRawBackground } from "./FormatModals/background";
import ConditionalModal from "./LinkModals/ConditionalModal";
import ContactModal from "./LinkModals/ContactModal";
import ExternalLexiaModal from "./LinkModals/ExternalLexiaModal";
import NewLexiaModal from "./LinkModals/NewLexiaModal";
import UrlModal from "./LinkModals/UrlModal";
import { createStatusBar } from "./helpers";
import { UnstyledEditor } from "./styledEditor";
import { LinkType } from "./types";

const EditorComponent: FC<{ path: string }> = ({ path }) => {
  const [editor, setEditor] = useState<TinyMCEEditor>();
  const [editorBody, setEditorBody] = useState<HTMLBodyElement>();
  const statusRef = useRef<HTMLElement | null>(null);
  const { catchError, alert: setAlertMessage } = useNotificationApi();

  const [lexia, setLexia] = useState<ILexia>();
  const [lexiaHtml, setLexiaHtml] = useState<string>();
  const [fragmentWrapper, setFragmentWrapper] = useState<FragmentWrapper>();
  const setLastEdited = useLastEdited()[1];

  const hasMultipleStoryLines = lexia?.story.hasMultipleStoryLines;

  useLexia(path, {
    onSuccess: ({ lexia, content }) => {
      switchLexia(lexia, content);
    },
    onError: () => {
      catchError("Nie znaleziono leksji", <q>{path}</q>);
      // TODO: create? create on save?
    },
  });

  const switchLexia = (newLexia: ILexia, fragmentWrapper: FragmentWrapper) => {
    const newLexiaHtml = fragmentWrapper.getLexiaHtml();

    setLastEdited(newLexia.url);

    setLexia(newLexia);
    setFragmentWrapper(fragmentWrapper);

    if (!editor) {
      setLexiaHtml(newLexiaHtml);
      return;
    }

    editor.setContent(newLexiaHtml);
    setLexiaHtml(editor.getContent());
    const iframeEl = editor.editorContainer.querySelector("iframe");
    const baseEl = iframeEl?.contentDocument?.querySelector("base");
    if (!baseEl) return;

    baseEl.href = newLexia.url.href;
  };

  // TODO: Are those needed if we use only the mutate method?
  const { mutate: createLexia } = useCreateLexia();
  const saveChanges = async () => {
    if (!lexia || !editor || !fragmentWrapper) return Promise.reject("Something is undefined");

    const content = editor.getContent();
    if (!fragmentWrapper.hasChanges && content === lexiaHtml) {
      return Promise.reject("Content has not changed");
    }
    fragmentWrapper.setLexiaHtml(content);
    return lexia.saveContent(fragmentWrapper);
  };

  const createNewLexia = (
    newLexiaName: string,
    newLexiaContent: LexiaHtml
  ) => {
    if (!lexia || !editor) return;

    createLexia(
      { collection: lexia.collection, html: newLexiaContent, title: newLexiaName },
      {
        onSuccess: (newLexia: ILexia) => {
          executeLinkAction(newLexia);
          saveChanges();
          navigate(newLexia.editPath);
        }
      }
    );
  };

  // TODO revive this mechanism, come up with a list of custom states
  // const setStatusTextContent = (status: string) => {
  //   if (!statusRef.current) return;
  //   statusRef.current.textContent = status;
  // };

  const openPreview = () => {
    if (!lexia) return;

    saveChanges().finally(() => {
      navigate(lexia.editPath.replace("/e/", "/v/"));
    });
  };

  const onInit = (_event: EditorEvent<unknown>, editor: TinyMCEEditor) => {
    setEditor(editor);
    createStatusBar(editor.editorContainer, statusRef);
  };

  const onLoadContent = (_event: EditorEvent<unknown>, editor: TinyMCEEditor) => {
    setTimeout(() => {
      setLexiaHtml(editor.getContent());
    });
  };

  const openNewLexiaModal = () => {
    setLinkModalType("new-lexia");
  };

  const [isDefaultLink, setIsDefaultLink] = useState(false);

  const openDefaultLinkMenu = (button: HTMLButtonElement) => {
    const { left } = button.getBoundingClientRect();
    setIsDefaultLink(true);
    setIsContextMenuOpen(true);
    setContextMenuPosition({ left, top: -10 });
  };

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] =
    useState<ContextMenuPosition>({
      top: null,
      left: null,
    });

  const { MENU_WIDTH, WIDE_MENU_WIDTH } = useContextMenu({
    isOpen: isContextMenuOpen,
    position: contextMenuPosition,
  });

  const openContextMenu = (e: MouseEvent) => {
    e.preventDefault();
    if (!editor) return;

    if (!isTextSelected(editor)) {
      selectWord(editor);
    }

    if (e.clientX + WIDE_MENU_WIDTH > window.innerWidth) {
      setContextMenuPosition({ top: e.clientY, left: e.clientX - MENU_WIDTH });
    } else {
      setContextMenuPosition({ top: e.clientY, left: e.clientX });
    }
    setIsContextMenuOpen(true);
  };

  const isTextSelected = (editor: TinyMCEEditor) => {
    const selection = editor.selection.getSel();
    return !!selection?.getRangeAt(0).toString().length;
  };

  const selectWord = (editor: TinyMCEEditor) => {
    const selection = editor.selection.getSel();
    selection?.modify("move", "forward", "word");
    selection?.modify("extend", "backward", "word");
  };

  const hideContextMenu = () => {
    setIsContextMenuOpen(false);
    setIsDefaultLink(false);
  }

  const onClick = (e: EditorEvent<MouseEvent>, _editor: TinyMCEEditor) => {
    hideContextMenu();

    // CTRL (Windows) or CMD (MacOS) + click
    if (!!lexia && checkCommandKey(e)) {
      const a = e.target as HTMLAnchorElement;
      if (a.tagName === "A" && a.href) {
        e.preventDefault();
        const targetPath = getTargetLexiaEditPath(a.href, lexia);
        if (targetPath) {
          navigate(targetPath);
        } else {
          window.open(a.href, "_blank");
        }
      }
    }
  };

  const onKeyDownOrUp = (e: EditorEvent<KeyboardEvent>) => {
    if (!editor) return;
    const commandPressed = checkCommandKey(e);
    editor.contentDocument.body.classList.toggle("ctrlDown", commandPressed);
  };

  // this is a significant simplification - context menu will handle more cases than ...
  // ... just static links. When more requirements are known, examine reducer pattern ...
  // ... or context for managing actions and non-editor components
  const [linkModalType, setLinkModalType] = useState<LinkType | null>(null);

  const executeScriptLinkAction = (script: string, targets: [string, ...string[]]) => {
    if (!fragmentWrapper || !editor) return;
    const id = fragmentWrapper.addConditionalLink(script, targets);
    editor.execCommand("mceInsertLink", false, { href: targets[0], id });
  };

  const executeLinkAction = (target: ILexia | string) => {
    if (typeof target !== "string") {
      target = relativeUrl(target.url, lexia!.url);
    }
    if (isDefaultLink) {
      setDefaultLink(target);
    } else {
      editor?.execCommand("mceInsertLink", false, target);
    }
    saveChanges();
  };

  const setDefaultLink = (href: string) => {
    if (!editor || !fragmentWrapper) return;
    fragmentWrapper.setLexiaHtml(editor.getContent());
    fragmentWrapper.setDefaultLinkHref(href);
  };

  const document_base_url = lexia ? getBaseUrl(lexia.url).href : undefined;

  const getLinkModal = (type: LinkType | null) => {
    if (!editor || !fragmentWrapper || !lexia) return;

    switch (type) {
      case "new-lexia":
        return (
          <NewLexiaModal
            close={closeModal}
            createNewLexia={createNewLexia}
            editor={editor}
            baseUrl={document_base_url}
          />
        );
      case "external-lexia":
        if (!lexia) throw new Error("Cannot open external lexia modal if lexia is undefined.");
        return (
          <ExternalLexiaModal
            close={closeModal}
            executeLinkAction={executeLinkAction}
            lexia={lexia}
          />
        );
      case "contact":
        return (
          <ContactModal
            close={closeModal}
            executeLinkAction={executeLinkAction}
          />
        );
      case "url":
        return (
          <UrlModal close={closeModal} executeLinkAction={executeLinkAction} />
        );
      case "conditional":
        return (
          <ConditionalModal
            close={closeModal}
            executeLinkAction={executeScriptLinkAction}
            storyLine={lexia.collection}
          />
        );
      case null:
      default:
        return null;
    }
  };

  const closeModal = () => {
    setLinkModalType(null);
  };

  const handleContextMenuAction = (
    action: LinkType,
    lexia?: ILexia
  ) => {
    if (!editor) return;

    if (action === "own-lexia" && !!lexia) {
      executeLinkAction(lexia);
    } else if (action === "unlink") {
      editor.dom.remove(editor.selection.getNode(), true);
    } else {
      setLinkModalType(action);
    }

    hideContextMenu();
  };

  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const deletionConfirmed = () => {
    if (!lexia) return console.error("This should never happen");
    setConfirmDeletion(false);

    lexia.delete().then(() => {
      navigate(lexia.collection.editPath);
      setAlertMessage("Leksja usunięta", false);
    }, () => {
      setAlertMessage("Nie udało się usunąć leksji", true);
    }).finally(() => {
      invalidateLexiaList(lexia.collection);
    });
  };

  const [backgroundModal, setBackgroundModal] = useState<IBackground | null>(null);

  useEffect(() => void 0);

  const quit = () => {
    saveChanges();
    let path = "/o/";
    if (lexia) {
      path = lexia.story.editPath.replace("/e/", path);
    }
    navigate(path);
  };

  const applyColor = (color: string) => {
    if (!editor) {
      console.warn("Editor is not defined");
      return;
    }
    if (editor.selection.getNode().tagName === "BODY") return;

    if (color === "REMOVE") {
      // this means that once a color is applied, the style will not be removed
      // not ideal, but it doesn't hurt us either
      editor.formatter.apply(`color-#000000`);
    }

    editor.formatter.register(`color-${color}`, {
      inline: "span",
      styles: { color: color },
    });
    editor.formatter.apply(`color-${color}`);

    editor.focus();
  };

  const mceExecCommand: TinyMCEEditor["execCommand"] = editor ?
    editor.execCommand.bind(editor) : (...args) => {
      console.warn("Command executed before editor was initialized", ...args);
      return false;
    };
  const menuExecCommand = (command: TCommand): void => {
    if (!lexia) return;
    let [cmd, arg] = Array.isArray(command) ? command : [command, undefined];
    switch (cmd) {
      case "New":
        return; // TODO
      case "Delete":
        if (lexia) setConfirmDeletion(true);
        return;
      case "Preview":
        return openPreview();
      case "Quit":
        return quit();
      case "Search":
        cmd = "SearchReplace";
        break;
      case "Map":
        return void navigate(lexia.mapPath);
      case "ForeColor":
        applyColor(arg as string);
        return;
      case "Background":
        if (fragmentWrapper) {
          setBackgroundModal(fragmentWrapper.getBackground());
        } else {
          console.error("FragmentWrapper is not defined");
        }
        return;
    }
    mceExecCommand(cmd, false, arg);
  };

  const [activeFormats, setActiveFormats] = useState<TActiveFormats>({});
  useEffect(() => {
    if (editorBody && !editor) throw new Error("Editor mysteriously disappeared!");
    if (!editor) return;

    const body = editor.getBody() as HTMLBodyElement;
    setEditorBody(body);
    body.classList.add("ui", "segment");

    const updateActiveFormats = () => {
      const selectedNode = editor.selection.getNode();
      const liNode = editor.dom.getParent(selectedNode, "li");
      const tagName = (liNode?.parentNode as HTMLElement)?.tagName;

      const formats: TActiveFormats = {
        numlist: tagName === "OL",
        bullist: tagName === "UL",
        undo: editor.undoManager.hasUndo() || undefined,
        redo: editor.undoManager.hasRedo() || undefined,
      };
      for (const format of Format) {
        formats[format] = editor.formatter.match(format);
      }
      setActiveFormats(formats);
    };

    editor.on("NodeChange", updateActiveFormats);
    return () => {
      editor.off("NodeChange", updateActiveFormats);
    };
  }, [editor]);

  const updateBackground = (background: IBackground) => {
    if (!editorBody) return;

    let image = background.image || "";
    if (background.image) {
      image = new URL(background.image, lexia!.url)
        .href
        .replace(/\\/g, '\\\\')
        .replace(/"/g, '\\22');
      image = `url("${image}")`;
    }

    const htmlStyle = editorBody.parentElement!.style;
    htmlStyle.backgroundColor = background.color || "";
    htmlStyle.backgroundImage = image;

    for (const cls of FragmentWrapper.controlledSegmentClasses) {
      editorBody.classList.toggle(cls, background.segmentClass.has(cls));
    }
  };

  useEffect(() => {
    if (!(editorBody && fragmentWrapper)) return;
    const background = fragmentWrapper.getBackground();
    updateBackground(background);
  }, [editorBody, fragmentWrapper]);

  const asyncSetBackground = async (rawBackground: IRawBackground) => {
    if (!fragmentWrapper) throw new Error("FragmentWrapper is not defined");
    if (!lexia) throw new Error("Lexia is not defined");

    let image: string | null = null;
    if (rawBackground.image) {
      const url: URL = await lexia.story.uploadImage(rawBackground.image);
      image = relativeUrl(url, lexia.url);
    }

    const background = { ...rawBackground, image };
    fragmentWrapper.setBackground(background);
    updateBackground(background);
  };

  const setBackground = (rawBackground?: IRawBackground) => {
    setBackgroundModal(null);
    if (rawBackground) {
      asyncSetBackground(rawBackground)
        .catch(e => setAlertMessage("Nie udało się ustawić tła: " + e, true));
    }
  };

  return (
    <MenuLayout
      execCommand={menuExecCommand}
      toolbar={
        <EditorMenuToolbar
          activeFormats={activeFormats}
          execCommand={menuExecCommand}
          saveChanges={saveChanges}
          openNewLexiaModal={openNewLexiaModal}
          openDefaultLinkMenu={openDefaultLinkMenu}
          openPreview={openPreview}
          quit={quit}
        />
      }
      menu={getEditorMenu(editor)}
      sidebarProps={{ lexia, onNavigate: saveChanges }}
    >
      <UnstyledEditor
        onInit={onInit}
        onLoadContent={onLoadContent}
        initialValue={lexiaHtml}
        onContextMenu={(e) => openContextMenu(e)}
        onClick={onClick}
        onKeyDown={onKeyDownOrUp}
        onKeyUp={onKeyDownOrUp}
        baseUrl={document_base_url}
      />
      {backgroundModal && <BackgroundModal background={backgroundModal} close={setBackground} />}
      {editor && lexia ? (
        <ContextMenu
          storyLine={lexia.collection}
          isLink={editor.selection.getNode().nodeName.toLowerCase() === "a"}
          isOpen={isContextMenuOpen}
          close={() => setIsContextMenuOpen(false)}
          position={contextMenuPosition}
          onAction={handleContextMenuAction}
          hasMultipleStoryLines={hasMultipleStoryLines}
        />
      ) : null}
      {editor ? getLinkModal(linkModalType) : null}
      <Confirm
        negative
        header="Potwierdź usunięcie"
        confirmButton="Usuń"
        cancelButton="Anuluj"
        open={confirmDeletion}
        onCancel={() => setConfirmDeletion(false)}
        onConfirm={deletionConfirmed}>
          Czy na pewno chcesz <b>bezpowrotnie</b> usunąć leksję<br /><q>{lexia?.title}</q>?
      </Confirm>
    </MenuLayout>
  );
};

export default EditorComponent;
