import { FC, useEffect, useId, useState } from 'react';
import { Comment, Form, Input } from 'semantic-ui-react';
import styled from 'styled-components';
import { ChatErrorMessage, ChatService, TChatMessages } from '../../services/chat';
import { SidebarBase } from '../menu/SidebarBase';
import { Sidebar } from '../menu/SidebarTypes';
import { AuthorIcon } from '../misc/authorIcon';

const MONTHS = Object.freeze("I II III IV V VI VII VIII IX X XI XII".split(' '));

const room = "test-room";
const chatService = ChatService.getInstance();

const ChatSidebar = Sidebar(() => {
  const messageInputId = useId();
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<TChatMessages>([]);

  const send = () => {
    if (!message.trim()) return;
    chatService.send(room, message);
    setMessage("");
  };

  useEffect(() => {
    const listener = (messages: TChatMessages) => {
      messages.forEach(message => message.seen = true);
      setMessages(messages);
    };
    chatService.join(room, listener);
    return () => {
      chatService.leave(room, listener);
    };
  }, []);

  return <SidebarBase header="Czat" buttons={
    <Form onSubmit={send}>
      <Input fluid autoFocus
        id={messageInputId}
        icon={{ name: 'send', link: true, onClick: () => {
          send();
          document.getElementById(messageInputId)?.focus();
        } }}
        placeholder='Napisz coś&hellip;'
        value={message}
        onChange={e => setMessage(e.target.value)}
      />
    </Form>
  }>
    <Comment.Group>
      {messages.map(({ text, author, date }) =>
        <ChatEntry key={date.getTime()} author={author} text={text} date={date} />)}
    </Comment.Group>
  </SidebarBase>;
});

const ChatEntry: FC<{
  text: string;
  author: string;
  date: Date;
}> = (entry) => {
  const { text, author, date } = entry;
  const shortDate =
    date.getDate() + ' ' + MONTHS[date.getMonth()] + ', ' +
    date.toLocaleTimeString('pl-PL', { timeStyle: 'short' });
  const fullDate =
    date.toLocaleString('pl-PL', { dateStyle: 'full', timeStyle: 'medium' });
  const isError = entry instanceof ChatErrorMessage;

  return <StyledComment>
    { isError ? null : <AuthorIcon author={author} /> }
    <Comment.Content>
      { isError ? <Comment.Author className="error" as='b'>
        Błąd
      </Comment.Author> : <Comment.Author as='b'>{author}</Comment.Author> }
      <Comment.Metadata>
        <time dateTime={date.toISOString()} title={fullDate}>{shortDate}</time>
      </Comment.Metadata>
      <Comment.Text>{text}</Comment.Text>
    </Comment.Content>
  </StyledComment>;
};

const StyledComment = styled(Comment)`
  clear: both;

  .error {
    color: #db2828 !important;
  }

  > .avatar {
    float: left;
    margin-top: .2em;
  }
`;

export default ChatSidebar;
