import { FC, useEffect, useState } from "react";
import { Button, Form, Header, Modal, Segment } from "semantic-ui-react";
import styled from "styled-components";
import FragmentWrapper, { IBackground } from "../../../story-api/fragment-wrapper";
import { ColorInput } from "../../misc/form/color";
import { FileInput } from "../../misc/form/file";

const SEGMENT_STYLES = Object.freeze({
  "basic": "(brak)",
  "": "Zwykła",
  "raised": "Z cieniem",
  "stacked": "Stos stron",
  "stacked tall": "Wysoki stos stron",
  "piled": "Kartki w nieładzie",
  "inverted": "Odwrócone kolory"
});

for (const key of Object.keys(SEGMENT_STYLES)) {
  for (const cls of key.split(" ")) {
    if (cls) FragmentWrapper.controlledSegmentClasses.add(cls);
  }
}

export interface IRawBackground extends Omit<IBackground, 'image'> {
  image: File | undefined;
}

export interface BackgroundModalProps {
  background: IBackground;
  backgroundUrl: URL;
  close: (newBackground?: IRawBackground) => void;
}

export const BackgroundModal: FC<BackgroundModalProps> = ({ background, close }) => {
  const [color, setColor] = useState<string>("");
  const [file, setFile] = useState<File | undefined>();
  const [segmentClass, setSegmentClass] = useState<string>();
  const url: string = file ? URL.createObjectURL(file) : (
    background.imageUrl?.href || background.image || ""
  );
  const escapedUrl = url.replace(/\\/g, '\\\\').replace(/"/g, '\\"');

  useEffect(() => {
    const classes = Array
      .from(background.segmentClass)
      .filter(cls => FragmentWrapper.controlledSegmentClasses.has(cls));
    classes.sort();
    setSegmentClass(classes.join(" "));
    setColor(background.color || "#ffffff");
  }, [background]);

  const save = () => {
    const segmentClassSet = new Set(segmentClass ? segmentClass.split(" ") : void 0);
    close({ color, image: file, segmentClass: segmentClassSet });
  };

  const onFileSelected = (files: FileList | null) => {
    const file = files ? files[0] : undefined;
    setFile(file);
  };

  return <Modal open={true} size="tiny" onClose={() => close()}>
    <Modal.Header>Tło</Modal.Header>
    <Modal.Content>
      <StyledForm onSubmit={save}>
        <Form.Field className="color" inline>
          <label>Kolor:</label>
          <ColorInput value={color} onChange={e => setColor(e.target.value)} />
        </Form.Field>
        <Form.Field inline>
          <label>Obrazek:</label>
          <FileInput value={background.image || ""} onChange={onFileSelected} />
        </Form.Field>
        <Form.Field inline>
          <label>Ramka:</label>
          <select value={segmentClass} onChange={e => setSegmentClass(e.target.value)}>
            {Object.entries(SEGMENT_STYLES).map(([value, text]) =>
              <option key={value} value={value}>{text}</option>
            )}
          </select>
        </Form.Field>
        <Form.Field>
          <label>Podgląd:</label>
          <Preview style={{
            backgroundColor: color,
            backgroundImage: escapedUrl ? `url(${escapedUrl})` : undefined,
          }}>
            <Header>Tło strony</Header>
            <div className={"ui segment " + segmentClass}>
              <Header>Ramka leksji</Header>
            </div>
          </Preview>
        </Form.Field>
      </StyledForm>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={() => close()}>Anuluj</Button>
      <Button primary onClick={save}>Zastosuj</Button>
    </Modal.Actions>
  </Modal>;
};

const StyledForm = styled(Form)`
  .field.inline {
    display: grid;
    grid-template-columns: 4em auto;
    align-items: center;
    gap: .6em;
  }

  .field > :not(label:first-of-type) {
    width: auto;
  }
`;

const Preview = styled(Segment)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 10em;
  overflow: hidden;
  border-color: #212121 !important;
  box-shadow: none !important;
  z-index: 0;

  .ui.segment {
    margin: 0;
    width: calc(62% + 1em);
    height: 6em;
    align-self: flex-end;
  }
`;
