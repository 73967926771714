import styled from "styled-components";

export const ContextMenuContainer = styled.div<{
  top: number;
  left: number;
}>`
  position: fixed;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  z-index: 99999999999;
`;
