// import { EditorOptions } from "tinymce";

const baseUrl = typeof document !== "undefined" ? document.baseURI : "https://inkah.amu.edu.pl/";

const tinyMceConfig/*: Omit<Partial<EditorOptions>, "selector" | "target">*/ = {
  branding: false,
  promotion: false,
  resize: false,
  height: "calc(100vh - 101px)",
  menubar: false,
  toolbar: false,
  plugins: [
    "advlist",
    "autolink",
    "lists",
    "image",
    "charmap",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "help",
    "wordcount",
  ],
  // paste_as_text: true,
  content_css: "/editor.css",
};

export interface ContactLinkType {
  proto: string;
  label: string;
  type: string;
  pattern: RegExp;
  placeholder: string;
  help?: string;
}

const phone = (proto: string, label: string) => ({
    "proto": proto,
    "label": label,
    "type": "tel",
    "pattern": /^\+[0-9]{11,}$/,
    "placeholder": "+48123456789",
    "help": "Podaj numer telefonu wraz z numerem kierunkowym kraju (np. +48).\n" +
            "Nie używaj spacji, nawiasów ani innych znaków specjalnych."
  });

const contactProtocols: ReadonlyArray<ContactLinkType> = Object.freeze([
  { "proto": "mailto:", "label": "Adres e-mail", "type": "email",
    "pattern": /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-.]{2,}$/,
    "placeholder": "example@example.com" },
  phone("tel:", "Numer telefonu"),
  // phone("sms:", "SMS"),
  phone("https://wa.me/", "WhatsApp"),
  { "proto": "https://m.me/", "label": "Messenger", "type": "text", "pattern": /^[\w\-\.]{5,}$/,
    "placeholder": "nazwa-uzytkownika" },
  { "proto": "https://t.me/", "label": "Telegram", "type": "text", "pattern": /^\w{4,}$/,
    "placeholder": "nazwa_uzytkownika" },
  { "proto": "gg:", "label": "Gadu-Gadu", "type": "text", "pattern": /^\d{3,}$/,
    "placeholder": "1234567" }
]);

const config = {
  baseUrl,
  autheliaUrl: new URL("/auth/", baseUrl).href,
  contactProtocols,
  tinyMceConfig
};

// export default configLocal;
export default config;
