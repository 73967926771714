import {
  cloneElement, FC, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo
} from 'react';
import { NotifierContext } from './reducer';
import Timer from './timer';
import { INotification, INotifierContext, MAX_NOTIFICATIONS } from './types';

export const NotificationBag: FC<{
  onDismiss?: (id: number) => Promise<unknown> | void;
  children?: (rendered: ReactNode) => ReactNode;
}> = ({
  onDismiss,
  children = i => i,
}) => {
  const { notifications, dismissToast: dismiss } = useContext(NotifierContext);

  const displayedNotifications = useMemo(() => {
    const filtered = MAX_NOTIFICATIONS ?
      notifications.slice(Math.max(notifications.length - MAX_NOTIFICATIONS, 0)) :
      [...notifications];
    filtered.reverse();
    return filtered;
  }, [notifications]);

  return displayedNotifications.length ? (
    <>
      {children(
        displayedNotifications.map(
          ({ id, children }) => (
            <NotificationWrapper
              key={id}
              dismiss={dismiss}
              onDismiss={onDismiss}
              id={id}
            >
              {children}
            </NotificationWrapper>
          ),
        ),
      )}
    </>
  ) : null;
};

function NotificationWrapper({ children, dismiss: origDismiss, onDismiss, id }: {
  children: ReactElement;
  dismiss: INotifierContext["dismissToast"];
  onDismiss?: (id: number) => Promise<unknown> | void;
  id: INotification["id"];
}) {
  const timer = useMemo(() => new Timer(), []);

  const dismiss = useCallback(async () => {
    if (onDismiss) await onDismiss(id);
    origDismiss(id);
  }, [origDismiss, id, onDismiss]);

  useEffect(() => {
    timer.callback = dismiss;
  }, [timer, dismiss]);

  useEffect(() => {
    return timer.clear.bind(timer);
  }, [timer]);

  return (
    <div
      onMouseEnter={() => timer.setRunning(false)}
      onMouseLeave={() => timer.setRunning(true)}
    >
      {cloneElement(children, { dismiss })}
    </div>
  );
}
