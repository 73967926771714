import { FC } from "react";
import { Icon, Menu } from "semantic-ui-react";
import { LinkType } from "../types";
import LinkLexiaMenu from "./LinkLexiaMenu";
import { ContextMenuContainer } from "./styled";
import { ContextMenuProps } from "./types";
import { useContextMenu } from "./useContextMenu";

const StaticLinkMenu: FC<ContextMenuProps> = (props) => {
  const { activeSubMenu, openSubMenu, TOP_BORDER_OFFSET_PX } =
    useContextMenu<LinkType>(props);

  const selectLinkType = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    type: LinkType | undefined
  ) => {
    if (!type) return;

    if (type === "own-lexia") {
      openSubMenu({ e, item: type, isWide: true });
    } else {
      props.onAction(type);
    }
  };

  const getAngleIconName = (subMenu: LinkType) =>
    activeSubMenu.name === subMenu ? "angle left" : "angle right";

  return props.isOpen ? (
    <ContextMenuContainer
      top={Number(props.position.top) - TOP_BORDER_OFFSET_PX}
      left={Number(props.position.left)}
    >
      <Menu vertical as="div">
        <Menu.Item
          name="new-lexia"
          active={activeSubMenu.name === "new-lexia"}
          onClick={(e, menuItem) =>
            selectLinkType(e, menuItem.name as LinkType)
          }
        >
          Nowa leksja
        </Menu.Item>
        <Menu.Item
          name="own-lexia"
          active={activeSubMenu.name === "own-lexia"}
          onClick={(e, menuItem) =>
            selectLinkType(e, menuItem.name as LinkType)
          }
        >
          Istniejąca leksja
          <Icon name={getAngleIconName("own-lexia")} />
        </Menu.Item>
        {props.hasMultipleStoryLines && <Menu.Item
          name="external-lexia"
          active={activeSubMenu.name === "external-lexia"}
          onClick={(e, menuItem) =>
            selectLinkType(e, menuItem.name as LinkType)
          }
        >
          Fabuła innego użytkownika
        </Menu.Item>}
        <Menu.Item
          name="url"
          active={activeSubMenu.name === "url"}
          onClick={(e, menuItem) =>
            selectLinkType(e, menuItem.name as LinkType)
          }
        >
          Adres URL
        </Menu.Item>
        <Menu.Item
          name="contact"
          active={activeSubMenu.name === "contact"}
          onClick={(e, menuItem) =>
            selectLinkType(e, menuItem.name as LinkType)
          }
        >
          Link kontaktowy
        </Menu.Item>
      </Menu>
      <LinkLexiaMenu
        isLink={props.isLink}
        isOpen={activeSubMenu.name === "own-lexia"}
        position={activeSubMenu.position}
        onAction={props.onAction}
        storyLine={props.storyLine}
      />
    </ContextMenuContainer>
  ) : null;
};

export default StaticLinkMenu;
