import { FC, InputHTMLAttributes, useId } from "react";
import styled from "styled-components";

export const ColorInput: FC<Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>> = ({ id, tabIndex, ...props }) => {
  const generatedId = useId();
  id = id || generatedId;

  return <StyledContainer className="ui right labeled input">
    <input type="text" value={props.value} onChange={props.onChange} />
    <label className="ui label button" htmlFor={id} tabIndex={ tabIndex || 0 }>
      Wybierz kolor&hellip;
    </label>
    <input type="color" id={id} {...props} tabIndex={-1} />
  </StyledContainer>;
};

const StyledContainer = styled.div`
  .ui.button {
    min-width: 8.8em;
  }

  input[type="color"] {
    position: absolute;
    left: .68em;
    top: .68em;
    border: 1px solid #5a5a5a;
    border-radius: 1px !important;
    width: 1.4em !important;
    height: 1.4em;
  }

  input[type="text"] {
    padding-left: 2.6em !important;
  }
`;
