// inspired by: https://gist.github.com/ncou/3a0a1f89c8e22416d0d607f621a948a9

import { DEFAULT_DURATION } from "./types";

export default class Timer {
  public callback: () => void = () => void 0;
  public timerId?: NodeJS.Timeout;
  private start = 0;
  private remaining = DEFAULT_DURATION;

  constructor() {
    this.setRunning(true);
  }

  setRunning(running: boolean) {
    this.clear();

    const t = new Date().getTime();
    if (running) {
      this.start = t;
      this.timerId = setTimeout(() => this.callback(), this.remaining);
    } else {
      this.remaining -= t - this.start;
    }
  }

  clear() {
    clearTimeout(this.timerId);
  }
}
