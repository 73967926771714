import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { Container, Dropdown, Header, Icon, Input, List, Message, Segment, StrictDropdownItemProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { useLexiaList } from '../../queries';
import { ILexia, IStoryLine } from '../../story-api';
import { relativeUrl as originalRelativeUrl } from '../../story-api/path';

const conditionTypes: Record<string, string> = {
  v: "Jeśli czytelnik odwiedził już leksję",
  d: "Jeśli wynik rzutu kostką",
  r: "Jeśli losowa wartość z przedziału [0,1) jest poniżej",
};
const conditionTypesAsProps = Object.entries(conditionTypes).map(([key, text]) => {
  text = text.replace(/ /g, '\xa0') + '\xa0\u2026';
  return { key, value: key, text };
});

const diceProps: (StrictDropdownItemProps & { labels?: string[], icons?: string[] })[] = [
  {value: 2, text: "moneta", icon: "ring", labels: ["orzeł", "reszka"]},
  {value: 6, text: "1 z 6", icon: "dice", icons: [
    "dice one", "dice two", "dice three", "dice four", "dice five", "dice six",
  ]},
  {value: 20, text: "1 z 20", icon: "dice d20"},
];

function diceSubconditions(value: number) {
  const dice = diceProps.find(d => d.value === value) || {};
  return Array(value).fill(0).map((_, idx) => ({
    icon: dice.icons ? dice.icons[idx] : dice.icon,
    label: dice.labels ? dice.labels[idx] : idx + 1,
  }));
}

const ConditionalLinkEditorComponent: FC<{
  change: (script: string | undefined, targets: string[]) => void;
  storyLine: IStoryLine | null;
}> = (props) => {
  const [conditionType, setConditionType] = useState<string | undefined>();
  const [selectedLexia, setSelectedLexia] = useState<ILexia | undefined>();
  const [treshold, setTreshold] = useState(0.5);
  const [dice, setDice] = useState<number | undefined>(undefined);
  const { data: lexiaList, error: error1, isError: isError1, isLoading } = useLexiaList(props.storyLine);
  const isError = !props.storyLine || isError1;
  const error = props.storyLine ? error1 : "Brak kontekstu linii fabularnej";
  const lexiaOpts = lexiaList?.map(lexia => {
    const url = lexia.url.toString();
    return {
      key: url,
      value: url,
      text: lexia.toString(),
    };
  });

  useEffect(() => {
    setSelectedLexia(undefined);
  }, lexiaList);

  const relativeBase = props.storyLine?.url;
  if (relativeBase && !relativeBase.pathname.endsWith("/")) {
    relativeBase.pathname += "/";
  }
  const relativeUrl = relativeBase ? (url: URL) => (
    originalRelativeUrl(url, relativeBase, false)
  ) : () => {
    throw new Error("No storyline to calculate relative URL relative to");
  };

  const lexiaDropdown = (onChange: (lexia: ILexia | undefined) => void) => <Dropdown
    search
    options={lexiaOpts}
    placeholder="(wybierz leksję)"
    error={isError}
    loading={isLoading}
    onChange={(_e, { value }) => onChange(
      lexiaList?.find(lexia => lexia.url.toString() === value)
    )}
  />;

  let conditionParam: ReactNode = null;
  let subconditions: undefined | {
    icon?: any;
    label: string | number;
    node?: ReactNode;
    lexia?: ILexia;
  }[];
  let script: string | null = null;
  let binaryCondition = true;
  if (props.storyLine) switch (conditionType) {
    case 'v':
      conditionParam = lexiaDropdown(setSelectedLexia);
      if (selectedLexia) {
        subconditions = [
          {
            icon: "hiking",
            label: "tak",
          },
          {
            icon: "dont",
            label: "nie",
          },
        ];
        const url = relativeUrl(selectedLexia.url)
        script = `v(${JSON.stringify(url)})`;
      }
      break;
    case 'd':
      conditionParam = <Dropdown simple item
        text={dice ? undefined : "(wybierz kostkę)"}
        options={diceProps}
        onChange={(_e, { value }) => setDice(value as number)}
        value={dice}
      />;
      if (dice) {
        subconditions = diceSubconditions(dice);
        script = `~~(Math.random() * ${dice})`;
        binaryCondition = false;
      }
      break;
    case 'r':
      conditionParam = <Input
        type="number" min="0.01" max="0.99" step="0.01" value={treshold}
        onChange={e => setTreshold(e.target.valueAsNumber)} />;
      subconditions = [
        {
          icon: "less than",
          label: "tak",
        },
        {
          icon: "greater than equal",
          label: "nie",
        },
      ];
      script = `(Math.random < ${treshold})`;
      break;
  }

  const [selectedLexias, setSelectedLexias] = useState<(ILexia | undefined)[]>([]);

  let urls: string[] = [];
  if (subconditions) {
    let isAnyMising = false;
    subconditions.forEach((sub, idx) => {
      sub.node = lexiaDropdown((lexia: ILexia | undefined) => {
        const newArr = [...selectedLexias];
        newArr[idx] = lexia;
        setSelectedLexias(newArr);
      });
      sub.lexia = selectedLexias[idx];
      if (!sub.lexia) isAnyMising = true;
    });

    if (isAnyMising) {
      script = null;
    } else {
      urls = subconditions.map(sub => relativeUrl(sub.lexia!.url));
      if (binaryCondition) {
        script = `${script} ? 0 : 1`;
      } else {
        script = `${script}`;
      }
    }
  }

  useEffect(() => {
    props.change(script || undefined, urls);
  }, [script]);

  return <>
    { isError ? <Message negative style={{ marginBottom: '2em' }}>
      <b>Błąd ładowania danych:</b> { error + "" }
    </Message> : null }
    <ConditionList>
      <ConditionRow>
        <Dropdown simple item
          text={conditionType ? undefined : '(wybierz warunek)'}
          options={conditionTypesAsProps}
          onChange={(_e, { value }) => setConditionType(value + "")}
          value={conditionType}
        />
        { conditionParam }
        { subconditions ? "to…" : null }
      </ConditionRow>
      { subconditions && subconditions.map(
        (sub, idx) => <SubConditionRow key={idx} icon={sub.icon} label={sub.label}>
          <Icon name='arrow right' /> przejdź do leksji:&emsp;{ sub.node }
        </SubConditionRow>
      ) }
    </ConditionList>
    { script && <PreviewContainer>
      <Segment scrolling>
        <Header as="h4">Podgląd wygenerowanego skryptu:</Header>
        <code>{ script }</code>
      </Segment>
    </PreviewContainer> }
  </>;
};

const ConditionList: FC<PropsWithChildren> = ({ children }) => <ConditionListContainer>
  <List>{ children }</List>
</ConditionListContainer>;

const ConditionRow: FC<PropsWithChildren> = ({ children }) => <List.Item>
  <List.Content><Icon name="question" size="large" /></List.Content>
  {
    (Array.isArray(children) ? children : [children]).filter(v => v).map(
      (child, idx) => <List.Content key={idx}>{ child }</List.Content>)
  }
</List.Item>;

const SubConditionRow: FC<PropsWithChildren & {
  icon?: any, label: string | number,
}> = ({ children, icon, label }) => <List.Item>
  <List.Content><Icon name={
    icon || "arrow alternate circle right outline"
  } size="large" /></List.Content>
  <SubConditionLabel>{ label }</SubConditionLabel>
  <List.Content>{ children }</List.Content>
</List.Item>;

const SubConditionLabel: FC<PropsWithChildren> = styled(List.Content)`
  min-width: 3.5em;
  text-align: right;
`;
  
const ConditionListContainer: FC<PropsWithChildren> = styled(Container)`
  padding-top: 1em;

  .item > .content {
    display: table-cell;
    width: auto !important;
    vertical-align: middle !important;
    padding: 0 0 0 0.5em;

    > .icon {
      width: 1.5em;
      margin-right: 1rem;

      &.question::after {
        display: block;
        content: ' ';
        border: 1px solid #000;
        width: 1.5em;
        height: 1.5em;
        transform: translateY(-1.25em) rotate(45deg);
        pointer-events: none;
      }
    }
  }

  > .ui.list > .item:first-of-type {
    margin-bottom: 1em;
  }

  input {
    padding: 1px !important;
  }

  input[type=number] {
    width: 5em;
    text-align: right;
  }
`;

const PreviewContainer = styled.div`
  margin-top: 3em;
  flex: 1;
  display: flex;
  align-items: flex-end;

  > div {
    max-height: 24vh;
    flex: 1;
  }
`;

export default ConditionalLinkEditorComponent;
